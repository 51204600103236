import React ,{useState, useEffect} from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import {Link, navigate} from 'gatsby';
import styled from 'styled-components';
import config from '../../aws-exports';
import {getEsDownDocs, createOnRedis} from '../../graphql/queries'
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { useSelector, useDispatch } from "react-redux";
import Loader from '../../assets/loading.gif';
import  secureLocalStorage  from  "react-secure-storage";
Amplify.configure(config);

const Login = () => {
    const {consoleLog} = require('../commonFunctions.js');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [generaicError, setgeneraicError] = useState('');
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    async function getTokenUser (){
        setLoading(true);
        let json = {
            userName: email.trim(),
            accessPass: password.trim(),
            type: 'prodocs'
        };    

        let loginUrl = '';
        if (process.env.GATSBY_ENV === 'DEV') {
            loginUrl = process.env.GATSBY_API_DEV_BASE_URL;
        }
        else if (process.env.GATSBY_ENV === 'QA') {
            loginUrl = process.env.GATSBY_API_QA_BASE_URL;
        }
        else if (process.env.GATSBY_ENV === 'STAGING') {
            loginUrl = process.env.GATSBY_API_STAGING_BASE_URL;
        }
        else if (process.env.GATSBY_ENV === 'PRODUCTION') {
            loginUrl = process.env.GATSBY_API_PRODUCTION_BASE_URL;
        }
        else{
            loginUrl = process.env.GATSBY_API_DEV_BASE_URL;
        }
    
        const success = await fetch(`${loginUrl}account/getToken`, {
            method: 'POST',
            body: new URLSearchParams({json: JSON.stringify(json)}),
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        .then(res => res.json())
        .then(json => { 
            return json;
        });
        if(success.status == 1){
            let clientData = {...success.clientData}
            consoleLog('getTokenUser clientData>',clientData);
            secureLocalStorage.setItem('client_data', JSON.stringify(clientData));	
            secureLocalStorage.setItem('user_token', success.token);
            const esd = await API.graphql({
                query: getEsDownDocs,
                variables: {
                    team_id: clientData.team_id
                }
            });
            consoleLog('getTokenUser es_down_docs>',esd.data.getEsDownDocs);
            secureLocalStorage.setItem('es_down_docs', esd.data.getEsDownDocs);
            
            const create_on_redis = await API.graphql({
                query: createOnRedis,
                variables: {
                  id: clientData.client_id,
                  index: 'prodocs_user_auth_data',
                  data: JSON.stringify(clientData)
                }
            });
            consoleLog('getTokenUser create_on_redis:', create_on_redis);

            // secureLocalStorage.setItem('user_templates', JSON.stringify(success.templates));
            // dispatch({ type: "USER_TEMPLATES", value: success.templates });
            navigate('/document');
        }
        else{
            setLoading(false);
            setgeneraicError('Invalid Credentials')
        }
    }
    const handleEmailChange = (e) => {
        setEmailError('');
        setEmail(e.target.value)
    }
    const handlePasswordChange = (e) => {
        setPasswordError('');
        setPassword(e.target.value)
    }
    const submitValue = () => {
        if(email == ''){
            setEmailError('Email Required')
        }
        if(password == ''){
            setPasswordError('Password Required')
        }
        if(email !== '' && password !== ''){
            getTokenUser();
        }
    }

    const submitKey = (e) => {
        if(e.key == 'Enter') {
            submitValue()
        }
    }

    return (
        <>
            { loading ?
                <LoadingContainer>
                    <img className="loader" src={Loader}/>
                </LoadingContainer>
            :
                <FormLogin>
                    <MDBContainer className="w-100">

                        <MDBRow className="w-100 align-items-center justify-content-center m-0">
                            <MDBCol md="6">

                                <form className="w-100">
                                    <p className="h4 text-center mb-4">Sign in</p>
                                    <label htmlFor="defaultFormLoginEmailEx" className="grey-text">
                                        Your email
                                    </label>
                                    <input type="email" id="defaultFormLoginEmailEx" className="form-control" onChange={handleEmailChange} onKeyPress={submitKey} />
                                    {emailError &&
                                        <div style={{color:'red'}}>{emailError}</div>
                                    }
                                    <br />
                                    <label htmlFor="defaultFormLoginPasswordEx" className="grey-text">
                                        Your password
                                    </label>
                                    <input type="password" id="defaultFormLoginPasswordEx" className="form-control" onChange={handlePasswordChange} onKeyPress={submitKey} />
                                    {passwordError &&
                                        <div style={{color:'red'}}>{passwordError}</div>
                                    }

                                    {generaicError && <div style={{color:'red'}} >{generaicError}</div> }
                                    <div className="text-center mt-4">
                                        <Btn onClick={submitValue}>
                                            <div className="w-100" style={{ cursor: "pointer" }}>
                                                Login
                                            </div>
                                        </Btn>
                                    </div>
                                </form>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </FormLogin>
            }
        </>
    )
};

export default Login;

const LoadingContainer = styled.div`
    height:100vh;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    background: transparent;
    .loader{
        -webkit-user-select: none;
        margin: auto;
        // background-color: rgba(255,255,255, 0.5);
        // transition: background-color 300ms;
    }
`

const FormLogin = styled.div`
    width:100%;
    height:85vh;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column
`
const Btn = styled.div`
    width:100%;
    height:40px;
    border-radius:5px;
    text-transform:uppercase;
    display:flex;
    align-items:center;
    justify-content:center;
    background:#fbc846;
    &:hover{
        background-color:#000000 !important;
        color:#ffffff !important;
    }
`